import { getMaterialOBDExceptionList, addPlane, deletePlane, updatePlane, exportMaterialOBDExceptions, queryMaterialTasks } from '@/api/materialrecord'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  createplane({ commit }, userInfo) {
    const { planeCode, companyId, type, materials } = userInfo
    return new Promise((resolve, reject) => {
        addPlane({ planeCode: planeCode, companyId: companyId, type: type, materialIds: materials }).then(response => {
        const data = response
         
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryMaterialOBDExceptionlist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, module, level, materialCode  } = pageParm
    return new Promise((resolve, reject) => {
        getMaterialOBDExceptionList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, 
          level: level, module: module, materialCode: materialCode  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

    querymaterialtasks({ commit }, pageParm) {
    const { pageSize, pageIndex, materialCode } = pageParm
    return new Promise((resolve, reject) => {
        queryMaterialTasks({ pageSize: pageSize, pageIndex: pageIndex, materialCode: materialCode }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  exportMaterialOBDExceptionlist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, module, level, materialCode, obdErrorCodeIds, onDownloadProgress  } = pageParm
    return new Promise((resolve, reject) => {
      exportMaterialOBDExceptions({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, 
        level: level, module: module, materialCode: materialCode, obdErrorCodeIds: obdErrorCodeIds }, onDownloadProgress).then(response => {
          const data = response
          resolve(data)
        }).catch(error => {
          reject(error)
        })
    })
  },

  deleteplanebyid({ commit }, planeInfo) {
    const { planeId } = planeInfo
    return new Promise((resolve, reject) => {
        deletePlane({ planeId: planeId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateplane({ commit }, planeInfo) {
    const { planeId, planeCode, companyId, type, materials } = planeInfo
    return new Promise((resolve, reject) => {
        updatePlane({ id: planeId, planeCode: planeCode, companyId: companyId, type: type, materialIds: materials }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}