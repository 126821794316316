import { getToken , getUserInfo } from '@/utils/auth'
import { addAuthApp, getAuthAppList, deleteAuthApp, updateAuthApp } from '@/api/authApp'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}
 
const actions = {
  createAuthApp({ commit }, authAppInfo) {
    const { name, company } = authAppInfo
    return new Promise((resolve, reject) => {
        addAuthApp({ appName: name, companyId: company }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAuthApplist({ commit }, pageParam) {
    const { pageSize, pageIndex, searchName, company } = pageParam
    return new Promise((resolve, reject) => {
        getAuthAppList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, companyId: company }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  deleteAuthAppbyId({ commit }, authAppInfo) {
    const { authAppId } = authAppInfo
    return new Promise((resolve, reject) => {
        deleteAuthApp({ id: authAppId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateAuthApp({ commit }, authAppInfo) {
    const { authAppId, name, company } = authAppInfo
    return new Promise((resolve, reject) => {
        updateAuthApp({ id: authAppId, appName: name, companyId: company }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

