<template>
    <div id="report-container">
        <el-row style="width:100％; margin-bottom: 15px; margin-top: -18px; padding: 25px;height: 116px" :class="dynamicTaskInfoClass">
            <el-col :span="7">
                <div style="margin-top：40px; margin-left：20px">
                    <div style="margin-bottom:10px; padding-left:15px;font-size: 18px;font-family: PingFang SC, PingFang SC-Semibold;font-weight: 600; margin-top: 10px;">{{ taskInfo.taskCode }}</div>
                    <div style="padding-left:15px;margin-top：10px; float: left;font-size: 14px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;">{{ taskInfo.taskTypeName }}</div>
                </div>
            </el-col>
            <el-col :span="12">
                <el-descriptions :column="2" title="" style="margin-top: 5px;">
                <el-descriptions-item label-class-name="detail-label" class-name="descItem" label="距离：">
                    <template #label>
                        <img class="task-avatar" src="@/icons/distance_small.png"  style="margin-right: 5px;"/>
                        距离
                    </template>
                    {{ taskInfo.distance }}
                </el-descriptions-item>
                <el-descriptions-item label-class-name="detail-label" class-name="descItem" label="开始时间">{{ taskInfo.startTime }}</el-descriptions-item>


                <el-descriptions-item label-class-name="detail-label" class-name="descItem">
                    <template #label>
                        <img class="task-avatar" src="@/icons/remaining_small.png" style="margin-right: 5px;"/>
                        时长
                    </template>
                    {{ taskInfo.costTime }}
                </el-descriptions-item>
                <el-descriptions-item label-class-name="detail-label" class-name="descItem" label="结束时间">{{ taskInfo.endTime }}</el-descriptions-item>
                </el-descriptions>
            </el-col>
            <el-col :span="3"></el-col>
            <el-col :span="2" style="float:right">
                <div style="width: 100%; color: #00ACCD; font-size:18px; font-weight: 700; margin: 10px; padding-bottom: 5px;font-size: 26px;font-family: PingFang SC, PingFang SC-Semibold; font-weight: Semibold; font-weight: 600;"  v-if="hideExportBtn">{{ taskInfo.statusName }}</div>
                <el-button style="float:right;color: #00ACCD;margin-top：10px;border-radius: 5px;padding-left: 25px;padding-right: 25px;font-family: PingFang SC, PingFang SC-Semibold; font-weight: 600;" v-if="showExportBtn" @click="exportFlightReport">导出报告</el-button>
            </el-col>
        </el-row>
        <el-row style="width:100％;margin-left: 1px;" class="boxShadow0262">
            <el-col :span="17" style="text-align:right;">
                <el-affix  position="bottom" :offset="100" style="margin-left: 10px;" v-if="isShowTaskLineAffix">
                    <div class="floating-block boxShadow0262"  :style="{ height: pageWH.floatingTaskLineheight + 'px' }">
                        <el-scrollbar style="width: 100%;">
                            <el-timeline  style="overflow: auto; min-width: 210px;"  v-if="isShowTaskLine">
                                <el-timeline-item
                                    v-for="(activity, index) in activities"
                                    :key="index"
                                    :icon="activity.icon"
                                    :type="activity.type"
                                    :color="activity.color"
                                    :size="activity.size"
                                    :hollow="activity.hollow"
                                    :timestamp="activity.timestamp" >
                                    <div style="font-size:10px;color: #666666;">{{ activity.content }}</div> 
                                    <div style="font-size:12px;font-weight:600;margin-top: 5px;">{{ activity.destinationItem }}</div>
                                </el-timeline-item>
                            </el-timeline>
                            <div style="width: 95%;text-align: center; padding-bottom: 5px; margin: 5px; margin-top: 85px;" v-if="isNoLinePoints">
                                <el-image style="width: 85%;" :src="nodatasmall" :fit="fit" />
                                <div style="font-size: 14px;font-family: PingFang SC, PingFang SC-Regular;margin-top: 10px;">暂无数据</div>
                            </div>
                        </el-scrollbar>
                    </div>
                </el-affix>
                <FlightMapView ref="mapRef"></FlightMapView>
            </el-col>
            <el-col :span="7"  style="text-align:left;">
                <el-row class="faultCodePanel">
                    <div class="report-title-flag" style="color: #333333; font-weight: 700; width: 100%; border-bottom: #dedede 1px solid;padding-bottom: 15px; padding-left: 0px;">
                        故障码
                    </div>
                    <div style="min-width: 320px; width: 100%;  color: #00ACCD;" :style="{ height: pageWH.faultListheight + 'px' }">
                        <el-scrollbar style="width: 100%;">
                            <ul v-infinite-scroll="load" class="infinite-list"  :style="{ height: pageWH.faultInfiniteHeight + 'px' }">   <!--  style="overflow: auto" -->
                                <li v-for="(faultCode, index) in faultList" :key="index" class="infinite-list-item">
                                    <div style="font-size:12px;font-weight:700;float:left; width:12%;padding: 8px; border-radius: 2px; text-align: center;" :style="[{'color': faultCode.textColor }, {'background-color': faultCode.bgColor }]">{{ faultCode.errorCode }}</div>
                                    <div style="float:left; width:48%;padding-left: 8px;padding-top: 3px;">
                                        <div style="font-size:12px;font-weight:600;color: #324257;">{{ faultCode.module }}</div>
                                        <div style="font-size:12px;font-weight:500;color: #999999;">{{ faultCode.occurDataTime }}</div>  
                                    </div>
                                    <div style="font-size:12px;float:left; width:16%;padding: 4px;border-radius: 3px;color: #fefefe;background-color:#00ACCD;margin-top: 8px;text-align: center;" v-if="faultCode.status == 0">
                                         已解决
                                    </div>
                                    <div style="font-size:12px;float:left; width:16%;padding: 4px;border-radius: 3px;color: #fefefe;background-color:rgba(205,0,0,0.59);margin-top: 8px;text-align: center;" v-if="faultCode.status != 0">
                                        未解决 
                                    </div>
                                    <div style="font-size:12px;font-weight:500;float:right; width:16%;padding: 8px;">
                                        <el-button link type="primary" @click="showFaultDetail(faultCode)" style="color:#00ACCD">详情</el-button>
                                    </div>
                                </li>
                            </ul>
                        </el-scrollbar>
                    </div>
                </el-row>
            </el-col>
        </el-row>
    </div>
    <el-dialog v-model="dialogFormVisible" title="故障详情" width="720px" class="faultDetail">
        <el-descriptions title="" :column="3"  direction="vertical" style="" :row="25">
            <el-descriptions-item label="错误码">
                <template #label>
                    <div class="desc-label">错误码</div>
                </template>
                {{ faultDetail.errorCode }}
            </el-descriptions-item>
            <el-descriptions-item label="故障发生时设备上电次数">
                <template #label>
                    <div class="desc-label">故障发生时设备上电次数</div>
                </template>
                {{ faultDetail.occurPoweredTimes }}
            </el-descriptions-item>
            <el-descriptions-item label="模块">
                <template #label>
                    <div class="desc-label">模块</div>
                </template>
                {{ faultDetail.module }}
            </el-descriptions-item>
            <el-descriptions-item label="等级">
                <template #label>
                    <div class="desc-label">等级</div>
                </template>
                {{ faultDetail.level }}
            </el-descriptions-item>
            <el-descriptions-item label="类型">
                <template #label>
                    <div class="desc-label">类型</div>
                </template>
                {{ faultDetail.typeStr }}
            </el-descriptions-item>
            <el-descriptions-item label="故障发生版本号">
                <template #label>
                    <div class="desc-label">故障发生版本号</div>
                </template>
                {{ faultDetail.occurVersion }}
            </el-descriptions-item>
            <el-descriptions-item label="故障发生时间">
                <template #label>
                    <div class="desc-label">故障发生时间</div>
                </template>
                {{ faultDetail.occurTimeStr }}
            </el-descriptions-item>
            <el-descriptions-item label="故障发生时间戳">
                <template #label>
                    <div class="desc-label">故障发生时间戳</div>
                </template>
                {{ faultDetail.occurTimesecondFormat }}
            </el-descriptions-item>
            <el-descriptions-item label="故障接收时间">
                <template #label>
                    <div class="desc-label">故障接收时间</div>
                </template>
                {{ faultDetail.receiveTimeStr }}
            </el-descriptions-item>
            <el-descriptions-item label="描述">
                <template #label>
                    <div class="desc-label">描述</div>
                </template>
                {{ faultDetail.cnDesc }}
            </el-descriptions-item>
        </el-descriptions>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false"  color="#00ACCD">确定</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
    import  FlightMapView  from './FlightReportMap.vue'
    import moment from 'moment'
    import { defineEmits } from 'vue'
    import { ref } from "vue";
    import storage from '@/utils/storage' 
    import { getUserInfo } from '@/utils/auth'
    import html2canvas from 'html2canvas';

    export default {
        /* icon在这里声明和在return声明，效果不一样，el-icon 需要引用的，得在这里声明，其他的如button的得在return声明 */
        components: {
            FlightMapView
        },
        methods: {
            capture() {
                var targetDiv = document.getElementById('report-container');
                // document.body
                html2canvas(targetDiv).then(canvas => {
                    const base64Img = canvas.toDataURL();
                    console.log(base64Img);
                    console.log('download toolsCenterFirmware')

                    this.$store.dispatch('report/exportFlightReport', {
                        taskCode: this.currTaskCode,
                        image: base64Img
                    }).then((response) => {
                        const blob = new Blob([response.data]);
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = "飞行报告-" + this.currTaskCode + ".xlsx";
                        link.click();
                        window.URL.revokeObjectURL(downloadUrl);
                    }).catch((error) => {
                        console.log(error)
                        this.loading = false
                    })

                })
            },
            exportFlightReport() {
                this.capture()
            },
            changeCurrPlane (params, callback) {
                console.log("子组件的传递到父组件的参数", params);
                console.log("模拟发送后台异步请求，延迟3s...");
                setTimeout(() => {
                    console.log("异步请求结束，执行回调函数");
                    callback("父组件传到子组件的文本666")
                }, 3000)
            },
            toggleSideMenu () {   
                console.log("点击按钮");
            },
            progressDatas () {
                return 80
            },
            progressColors () {
                return '#00ACCD'
            },
            refreshTaskInfo (callback) {
                this.$store.dispatch('flightTask/queryTaskInfo', {
                    taskId: this.currTaskId
                }).then((data) => {
                    this.loading = false
                    this.taskInfo = {
                        taskCode: data.taskCode,
                        costTime: this.buildFormatTime(data.totalTime),
                        endTime: data.endTime,
                        startTime: data.startTime,
                        distance: data.totalDistance + 'm',
                        statusName: data.status == "landed" ? "已完成" : "飞行中",
                        taskTypeName: data.taskType ===  0 ? "航线任务" : "非航线任务"
                    }
                    this.currTaskType = data.taskType
                    this.isShowTaskLineAffix = data.taskType ===  0 ? true : false
                    this.showExportBtn = (data.status == "landed") || (data.status == "timeOut")
                    this.hideExportBtn = (data.status != "landed") && (data.status != "timeOut")
                    this.dynamicTaskInfoClass = ((data.status == "landed") || (data.status == "timeOut")) ? "dynamicTaskLanded" : "dynamicTaskNotLanded"
                    callback()
                }).catch((error) => {
                    console.log(error)
                    this.loading = false
                })
            },
            buildFormatTime(seconds) {
                let hours = Math.floor(seconds / 3600);
                let minutes = Math.floor((seconds % 3600) / 60);
                let remainingSeconds = seconds % 60;
                let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
                return formattedTime
            },
            refreshTaskRealtimeInfo () {
                this.$store.dispatch('flightMonitor/queryMaterialRealTimeTask', {
                materialId: this.currTaskCode.substring(0, 11),
                taskId: this.currTaskCode
                }).then((data) => {
                    this.progressVal = data == null ? 100 : data.progress
                }).catch(() => {
                    this.loading = false
                })
            },
            refreshTaskLineInfo () {
                this.$store.dispatch('flightTask/queryTaskLineInfo', {
                    taskLineId: this.currTaskLineId
                }).then((data) => {
                    this.loading = false
                    this.taskTableData = data.linePoints
                    if (this.taskTableData == null || this.taskTableData.length == 0) {
                        this.isNoLinePoints = true
                        this.isShowTaskLine = false
                    } else {
                        this.isNoLinePoints = false
                        this.isShowTaskLine = true
                    }

                    this.activities = ref([])
                    for(let inx in this.taskTableData) {
                        if (this.taskTableData[inx].wayPointType) {
                            this.activities.push({
                                hollow: true,
                                // content: inx == 0 ? "起点" : "途经点",
                                content: this.taskTableData[inx].wayPointName != '' ? this.linePointMap[this.taskTableData[inx].wayPointType] + "("+ this.taskTableData[inx].wayPointName + ")" : this.linePointMap[this.taskTableData[inx].wayPointType],
                                destinationItem: this.taskTableData[inx].location,
                                timestamp: '('+  (this.taskTableData[inx].longitude).toFixed(7)  + ', ' + (this.taskTableData[inx].latitude).toFixed(7) + ')',
                                latlon: [this.taskTableData[inx].longitude, this.taskTableData[inx].latitude]
                            })
                        } else {
                            this.activities.push({
                                hollow: true,
                                content: inx == 0 ? "起点" : "途经点",
                                destinationItem: this.taskTableData[inx].location,
                                timestamp: '('+  (this.taskTableData[inx].longitude).toFixed(7)  + ', ' + (this.taskTableData[inx].latitude).toFixed(7) + ')',
                                latlon: [this.taskTableData[inx].longitude, this.taskTableData[inx].latitude]
                            })
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                    this.loading = false
                })
            },
            refreshTaskLineAllStatus(tracks) {
                const activeArray = this.activities
                if (tracks != null && tracks.length > 0 && activeArray != null && activeArray.length > 0) {
                    for(var k in tracks) {
                        for (var inx in activeArray) {
                        if (inx == ("" + (activeArray.length - 1))) {
                            if (this.progressVal < 98) {
                                continue
                            }
                        }
                        
                        if(activeArray[inx].hollow == false) {
                            continue
                        }

                        if(Math.abs(activeArray[inx].latlon[0] - tracks[k].lon) < 0.001 && Math.abs(activeArray[inx].latlon[1]  - tracks[k].lat) < 0.001)  {
                            activeArray[inx].hollow = false
                            activeArray[inx].color = '#00accd'
                            break
                        }
                        }
                    }
                    this.activities = activeArray
                }
            },
            refreshTaskLineStatusByCurrPoint(craftData) {
                let track = null
                if (craftData != null) {
                    // 刷新左栏任务航线
                    track = [craftData.lon, craftData.lat]
                } 

                const activeArray = this.activities
                if (track != null && track.length > 0 && this.activities != null && this.activities.length > 0) {
                    for (var inx in activeArray) {
                        if (inx == ("" + (activeArray.length - 1))) {
                            if (this.progressVal < 98) {
                            continue
                            }
                        }

                        if(Math.abs(activeArray[inx].latlon[0] - track[0]) < 0.001 && Math.abs(activeArray[inx].latlon[1]  - track[1]) < 0.001)  {
                            activeArray[inx].hollow = false
                            activeArray[inx].color = '#00accd'
                        }
                    }
                    this.activities = activeArray
                }
            },
            refreshTaskFaultCode () {
                this.$store.dispatch('flightTask/queryTaskFaultInfo', {
                    taskCode: this.currTaskCode
                }).then((data) => {
                    this.loading = false
                    this.faultList = []
                    this.faultList = data
                    for (let inx in this.faultList) {
                        this.faultList[inx].occurDataTime = this.formatTimestampToDate(this.faultList[inx].occurTime * 1000)
                        this.faultList[inx].module = this.moduleMap[this.faultList[inx].moduleId]
                        this.faultList[inx].textColor = this.textColorMap["" + this.faultList[inx].level]
                        this.faultList[inx].bgColor = this.bgColorMap["" + this.faultList[inx].level]
                    }

                    this.loading = false
                }).catch((error) => {
                    console.log(error)
                    this.loading = false
                }) 
            },
            showFaultDetail (faultData) {
                this.faultDetail = faultData
                this.faultDetail.module = this.moduleMap[faultData.moduleId]
                this.faultDetail.occurTimeStr = this.formatTimestampToDate(faultData.occurTime * 1000) 
                this.faultDetail.receiveTimeStr = this.formatTimestampToDate(faultData.receiveTime * 1000) 
                this.faultDetail.typeStr = this.errorTypeMap["" + faultData.type]
                this.faultDetail.occurTimesecondFormat = this.buildFormatTime(faultData.occurSecond)
                this.dialogFormVisible = true
            },
            drawCurrAircraftRaskLine() {
               // console.log(currTaskLineId);
               //  console.log(currTaskCode);
                this.$refs.mapRef.drawAircraftTaskLine(this.currTaskLineId, this.currTaskCode, this.refreshTaskLineAllStatus, this.currTaskType, this.currMaterialCode)
            },
            formatTimestampToDate (timestamp) {
                return moment(timestamp).utcOffset('+0800').format('YYYY-MM-DD HH:mm:ss')
            },
            refreshMaterialStatus(statusData) {
                for(let inx in statusData) {
                    if (statusData[inx].taskCode == this.currTaskCode) {
                        this.progressVal = statusData[inx].process
                        this.$refs.mapRef.drawAircraftStatus(statusData[inx])
                        this.refreshTaskLineStatusByCurrPoint(statusData[inx])
                    }
                }
                
            }, 
            refreshFlightTaskStatus(taskData) {
                this.showExportBtn = (taskData.status == "landed")
                this.hideExportBtn = (taskData.status != "landed")
                this.dynamicTaskInfoClass = taskData.status == "landed" ? "dynamicTaskLanded" : "dynamicTaskNotLanded"
            },
            openSocket(self) {
                if (typeof WebSocket == "undefined") {
                    console.log("您的浏览器不支持WebSocket");
                } else {
                    console.log("您的浏览器支持WebSocket");
                    //实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
                    var userInfo = getUserInfo();
                    var userCodeWithTimestamp = new Date().getTime()+"-"+userInfo.id
                    var socketUrl = "ws://127.0.0.1:9901/bd-gms/websocket/monitor/" + userCodeWithTimestamp;
                    if (location.hostname.includes("192.168") || location.hostname.includes("47.106.132")) {
                        socketUrl = "ws://"+ location.hostname + ":9901/bd-gms/websocket/monitor/" + userCodeWithTimestamp;
                    }
                    if (location.hostname.includes("boundaryai.cn")) {
                        socketUrl = "wss://"+ location.hostname + "/bd-gms/websocket/monitor/" + userCodeWithTimestamp;
                    }
                    if (this.socket != null) {
                        this.socket.close();
                        this.socket = null;
                    }

                    //打开事件
                    this.socket = new WebSocket(socketUrl);
                    //打开事件
                    this.socket.onopen = function() {
                        console.log("websocket已打开");
                    };
                    //获得消息事件
                    this.socket.onmessage = function(msg) {
                        console.log(msg.data);
                        if (msg.data == null || msg.data.indexOf('socket_heartbeat') > -1) {
                            return
                        }
                        // 发现消息进入    开始处理前端触发逻辑
                        const data = JSON.parse(msg.data);
                        if (data.type === 'onlineHeartBeats') {
                            self.refreshMaterialStatus(data.data)
                        }

                        if (data.type === 'landedFlightTask') {
                            self.refreshFlightTaskStatus(data.data)
                        }
                    };
                    //关闭事件
                    this.socket.onclose = function() {
                        console.log("websocket已关闭");
                    };
                    //发生了错误事件
                    this.socket.onerror = function() {
                        console.log("websocket发生了错误");
                    };
                }
            },
            initPage() {
                var browerWidth = window.innerWidth; //浏览器可视宽度
                var browerHeight = window.innerHeight; //浏览器可视宽度
                if (browerHeight < 739) {
                    browerHeight = 739
                } 
                // 
                this.pageWH.faultInfiniteHeight =  (browerHeight - 739 ) + 413   // 423+5
                this.pageWH.floatingTaskLineheight =  (browerHeight - 739 ) + 373   // 368+10；  其中 368=620-252(即991-739)
                this.pageWH.faultListheight =  (browerHeight - 739 ) + 418  // 改造页面423+10
               // this.pageWH.mapWidth = browerWidth - 
            }
        },
        data() {
            const nodatasmall  = require('@/assets/nodata.png')
            const msg = ref("setup script");
            const emit = defineEmits(['changeCurrPlane'])
            const taskInfo = {
                costTime: '18:00',
                startTime: '2023-08-19 14:00',
                distance: '13.8km'
            }
            const count = [1, 2, 3, 4]
            const cascount  = [1, 2, 3, 4]
            const load = () => {
               // console.log("ul scroll")
            }
            const errorTypeMap = {
                "0": "永久故障",
                "1": "实时故障",
                "false": "永久故障",
                "true": "实时故障",
                "Temp Err": "实时故障",
                "Permanent Err": "永久故障"
            }
            const linePointMap = {
                16: "途径",
                19: "盘旋",
                21: "降落",
                22: "起飞"
            }

            const moduleMap = {
                "0": "系统软件"   //  SYS_SW
            }

            const activities =[{
                    content: '--',   // 途经点
                    destinationItem: '--',  // 航点1
                    timestamp: '-, -',
                }
            ]
            const faultList = []
            const currTaskId = ""
            const currTaskCode = ""
            const currTaskLineId = ""
            const dialogFormVisible = false
            const faultDetail = {}
            const hideExportBtn = true
            const showExportBtn = false
            const dynamicTaskInfoClass = "dynamicTaskNotLanded"
            return {
                emit,
                isShowTaskLineAffix: false,
                errorTypeMap,
                moduleMap,
                activities,
                linePointMap,
                faultList,
                msg,
                taskInfo,
                showExportBtn,
                hideExportBtn,
                dynamicTaskInfoClass,
                count,
                cascount,
                currMaterialCode: "",
                currTaskId,
                currTaskLineId,
                currTaskCode,
                currTaskType: 0,
                progressVal: 0,
                dialogFormVisible,
                faultDetail,
                nodatasmall,
                isShowTaskLine: true,
                isNoLinePoints: false,
                pageWH: {},
                textColorMap: {
                    '0': "#F43A20",
                    '1': "#FB8D00",
                    '2': "#E11FAD",
                    '3': "#15A6FD",
                    '4': "#999999"
                },
                bgColorMap: {
                    '0': "rgba(244,58,32,0.09)",
                    '1': "rgba(249,146,31,0.09)",
                    '2': "rgba(225,31,173,0.09)",
                    '3': "rgba(21,166,253,0.09)",
                    '4': "rgba(153,153,153,0.25)"
                },
                load
            };
        },
        mounted: function() { 
            this.currTaskId = this.$route.query.taskId
            this.currTaskCode = this.$route.query.taskCode
            this.currTaskLineId = this.$route.query.taskCode   // lineId
            this.currMaterialCode = this.$route.query.materialCode

            this.refreshTaskRealtimeInfo()
            this.refreshTaskInfo(this.drawCurrAircraftRaskLine)
            this.refreshTaskLineInfo()
            this.refreshTaskFaultCode()
           
           
            this.openSocket(this)
            this.initPage()
            window.addEventListener('resize', this.initPage)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.initPage)
        },
        unmounted () {
            // 在Vue销毁前断开WebSocket服务器连接
            this.socket.close();
        }
    };
</script>

<style>
.app-container {
    padding: 1px;
}

.el-progress-bar__outer{border-radius: 0; background-color:#3a3e63}

.el-progress-bar__inner{border-radius: 0;}

.el-progress__text{display: none;}

.el-progress-bar{padding: 0 1.25rem;}
 
.infinite-list {
 /* height: 675px; */
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list .infinite-list-item {
  /* display: flex; */  /*  居中 */
  align-items: center;
  justify-content: center;
  height: 50px;
  background: #FFFFFF;   /* var(--el-color-primary-light-9) */
  margin: 3px;
  color: var(--el-color-primary);
  padding: 10px;
}
.infinite-list .infinite-list-item + .list-item {
  margin-top: 10px;
}

.el-row {
   /* margin: 5px !important; */
}

.floating-block {
    position: fixed;
    z-index: 999;
    margin: 20px;
    padding: 20px 10px 20px 20px;
    background: #ffffff;
    /* height: 620px;   elemenet-plus 的滚动条要固定高度  */
    overflow: auto; /* 添加 overflow 属性 */

    border-radius: 4px;
    box-shadow: 0px 2px 6px 2px rgba(138,152,180,0.21); 
}

/** 解决timeline 文字对齐兼容性问题 */
.el-timeline-item__wrapper {
    text-align: justify;
}

.faultDetail .el-descriptions__label {
    margin-top: 20px !important;
}

.dynamicTaskLanded {
    background: linear-gradient(270deg,#00accd, #08b7d9) !important;
    color: #FFFFFF;
    .el-descriptions__body {
        background: linear-gradient(270deg,#06b1d2de, #0ab5d6) !important;
    }

    .el-descriptions__body .el-descriptions__content {
        color: #FFFFFF !important;
    }

    .el-descriptions__content:not(.is-bordered-label) {
        font-weight: 500!important;
    }

    .detail-label {
        color: #FFFFFF !important;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        opacity: 0.7;
    }

    .task-avatar {
        filter:brightness(100)
    }
}

.dynamicTaskNotLanded {
    background: linear-gradient(270deg,#FFFFFF, #FFFFFF);
    .el-descriptions__body {
        background-color: #FFFFFF
    }

    .el-descriptions__body .el-descriptions__content {
        color: #333333 !important;
        font-weight: 600 !important;
    }

    .detail-label {
        color: #999999 !important;
    }
}

.el-timeline-item__wrapper {
      top: 1px !important;
}

.desc-label {
    margin-right: 5px;
    font-size: 12px;
    color:#7E8A95;
    font-weight: 500;
    margin-top: 25px;
}

.faultCodePanel {
    margin-left: 28px;
    box-shadow: 0px 2px 6px 2px rgba(138, 152, 180, 0.21);
    padding-left: 0px;
}

 .report-title-flag::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 12px;
    background: #00accd;
    margin-right: 10px; /* 可选，如果需要在方块后面留出一些空间 */
}

.report-title-flag {
    color: #324257; 
    font-weight: 700;
    padding-top: 15px;
}
</style>
 