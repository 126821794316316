<template>
  <div class="login-container">
    <div class="side-image">
      <img src="@/assets/login_log.png" class="bg_logo_pic"/>
      <img src="@/assets/login_log_name_small.png" class="bg_logo_name" />
    </div>
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
      <div class="title-container">
        <h3 class="title">
          <img src="@/assets/logo_gms_welcome.png">
        </h3>
      </div>

      <el-form-item prop="userCode">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="userCode"
          v-model="loginForm.userCode"
          placeholder="请输入登录账号"
          name="userCode"
          type="text"
          tabindex="1"
          auto-complete="on"
        />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="password" class-name='custom-class'/>
        </span>
        <el-input
          ref="password"
          v-model="loginForm.password"
          type="password"
          placeholder="请输入密码"
          maxlength="16"
          name="password"
          tabindex="2"
          auto-complete="on"
          @keyup.enter.native="handleLogin"
          :show-password="true"
        />
      </el-form-item>

      <div class="remember-password">
        <el-checkbox v-model="remember"></el-checkbox>
        <span>记住密码</span>
      </div>
      <el-button class="login-btn" :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">登录</el-button>

      <!-- <div class="tips">
        <span style="margin-right:20px;">userCode: admin</span>
        <span> password: any</span>
      </div> -->

    </el-form>
  </div>
</template>
 
<script>
const Base64 = require('js-base64').Base64
import { encrypt } from '@/utils/secret'
import { getCookie, setCookie } from '@/utils/auth'
import { login, logout, getInfo } from '@/api/user'
import { ElMessage } from 'element-plus';
export default {
  name: 'Login',
  data() {
    const validateUserCode = (rule, value, callback) => {
      if (!(/^\d{6}$/).test(value) && value !== 'admin') {
        callback(new Error('请输入正确的用户名'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
            callback(new Error('请输入6位以上长度密码'))
        } else {
            callback()
        }
    }
    return {
      loginForm: {
        userCode: '',
        password: ''
      },
      loginRules: {
        userCode: [{ required: true, trigger: 'blur', validator: validateUserCode }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      loading: false,
      passwordType: 'password',
      redirect: '',
      remember: false
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/login', {
            userCode: this.loginForm.userCode,
            password: encrypt(this.loginForm.password) //
          }).then(() => {
            // remenber me的设置 setCookie
            this.setUserRemenber()
            this.$router.push({ path: this.redirect || '/' })
            this.loading = false
          }).catch((error) => {
           // ElMessage.error(error)
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    setUserRemenber() {
      if(this.remember){
        setCookie("userCode", this.loginForm.userCode)
        let passWord = Base64.encode(this.loginForm.password)
        setCookie("password", passWord)    
      }else{
        setCookie("userCode", "")
        setCookie("password", "")
      } 
    }
  },
  mounted: function() {
    let userCode = getCookie("userCode")
    let password = getCookie("password")
    if(userCode && password){
      this.loginForm.userCode = userCode;
      this.loginForm.password = Base64.decode(password);
      this.remember = true;
    }
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg:#283443;
$light_gray:#fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
   /* width: 85%;  */
    border: 0px;

    .el-input__wrapper {
        border: 0px;
        background-color:#f1f1f1;
        box-shadow:#f1f1f1 0 0 0 0
    }

    input {
      background: transparent;
      border: 0px;

      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: #333;
      height: 47px;
      caret-color: #444;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #f1f1f1 inset !important;
        -webkit-text-fill-color: #333 !important;
      }
    }
  }

  .el-input__wrapper {
    display: flex;
  }

  .el-form-item__content {
     flex-wrap: revert !important;
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #f1f1f1;
    border-radius: 5px;
    color: #454545;
    margin-bottom: 22px
  }

  .remember-password {
    text-align: right;
    line-height: 20px;
    margin-top: -7px;
    margin-bottom: 40px;
    font-size: 14px;
    color: #536366;

    .el-checkbox:last-of-type {
      margin-right: 6px;

      .el-checkbox__inner {
        border-color: #d3d3d3;
        border-radius: 1px;
      }

      &.is-checked .el-checkbox__inner {
        border-color: #02C4E7;
        background-color: #02C4E7;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: hidden;

  .side-image {
    height: 100%;
    width: 26%;
    opacity: 0.8;
    background: linear-gradient(270deg,#daf2fd, #f3faff 99%);

    .bg_logo_pic {
      display: flex;
      margin: auto;
      margin-top: 40%;
    }

    .bg_logo_name {
      display: flex;
      margin: auto;
      margin-top: 53px;
    }
  }

  .login-form {
    position: relative;
    top: 50%;
    width: 460px;
    max-width: 100%;
    padding: 0 35px;
    margin: 0 auto;
    margin-top: -200px;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: red;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      display: flex;
      align-items: center;
      font-size: 30px;
      color: #595757;
      margin: 40px auto;
      text-align: center;
      font-weight: bold;
    }

    img {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .login-btn {
    background-image: linear-gradient(134deg, #05E4F5 0%, #02C4E7 100%);
    border-radius: 25px;
    border-radius: 25px;
    border: 0 none;
  }
}

.el-button .el-button--primary {
  background: #00ACCD !important;
}
</style>
