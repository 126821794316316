import request from '@/utils/request'
 
export function addMaterial(data) {
  return request({
    url: '/bd-gms/device/material',
    method: 'post',
    data
  })
}

export function exportMaterialOBDExceptions(data, onDownloadProgress) {
    return request({
      url: '/bd-gms/record/material/obdexception/export',
      method: 'post',
      responseType: 'blob',
      data,
      onDownloadProgress: onDownloadProgress
    })
  }
  

export function getMaterialOBDExceptionList(data) {
  return request({
    url: '/bd-gms/record/material/obdexception/list',
    method: 'post',
    data
  })
}


export function queryMaterialTasks(data) {
  return request({
    url: '/bd-gms/record/material/tasks',
    method: 'post',
    data
  })
}

export function deleteMaterial(data) {
    return request({
      url: '/bd-gms/device/material' + "?materialId=" + data.materialId,
      method: 'delete'
    })
  }
