import { getFaultCodeList, updateFaultCodeNoticed } from '@/api/faultCode'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  queryFaultCodeList({ commit }, pageParam) {
    const { pageSize, pageIndex, searchValue, module, type, level } = pageParam
    return new Promise((resolve, reject) => {
        getFaultCodeList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchValue, module:module, type:type, level:level }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
 
  modFaultCode({ commit }, param) {
    const { faultCodeId,  enableNoticed } = param
    return new Promise((resolve, reject) => {
        updateFaultCodeNoticed({ id: faultCodeId, enableNoticed: enableNoticed }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

