<template>
    <el-row gutter="1"  class="main-content" style="margin-bottom: 20px;">
        <el-col :span="6" v-if="showPlaneView"><div class="grid-content" />
            <div style="width: 100%; color: #324257; font-weight: 700; margin: 5px; padding-bottom: 5px; ">
                {{ materialDetail.materialCode }}
             </div>
              <el-image style="width: 90px; height: 65px; margin: 5px;" :src="planeImage" :fit="fit" />
        </el-col>
        <el-col :span="14"><div class="grid-content" />
            <el-descriptions :column="3" title="" direction="vertical" style="margin-top: 50px;">
                <el-descriptions-item label-class-name="detail-label" class-name="descItem" label="出厂时间：">{{ materialDetail.createdTime }}</el-descriptions-item>
                <el-descriptions-item label-class-name="detail-label" class-name="descItem" label="所属公司：">{{ materialDetail.company }}</el-descriptions-item>
                <el-descriptions-item label-class-name="detail-label" class-name="descItem" label="类型">{{ materialDetail.typeName }}</el-descriptions-item>
             </el-descriptions> 
        </el-col>
        <el-col :span="4" v-if="showPlaneView"><div class="grid-content" />
        </el-col>
    </el-row>
    <el-row gutter="1"  class="main-content">
        <el-col :span="24" class="grid-content">


            <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                <el-tab-pane label="任务记录" name="first">
                    <el-row>
                        <el-col :span="24">
                            <el-table :data="taskTableData" style="width: 100%; font-weight: 500;" :height="pageWH.tasktableHeight">
                                <el-table-column  prop="taskCode" label="任务编号"></el-table-column>
                                <el-table-column prop="startTime" label="任务时间"></el-table-column>
                                <el-table-column prop="status" label="状态"></el-table-column>
                                <el-table-column prop="taskInfo" label="基本信息">
                                    <template #default="scope">
                                            <div>总距离：{{ scope.row.totalDistanceKM }} km</div>
                                            <div>总时长：{{ scope.row.totalTimeCost }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="operation" label="操作">
                                    <template #default="scope">
                                        <el-button link type="primary" style="color:#00ACCD" @click="handleLink(scope.$index, scope.row)">任务报告</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="9" class="grid-content"></el-col>
                        <el-col :span="15" class="pager-content">
                            <el-pagination
                                v-model:current-page="taskTableCurrentPage"
                                v-model:page-size="taskTablePageSize"
                                :page-sizes="[10, 20, 50, 100, 200]"
                                :small="small"
                                :disabled="disabled"
                                :background="background"
                                layout="total, prev, pager, next, sizes, jumper"
                                :total="taskTableTotalNum"
                                @size-change="handleTaskTableSizeChange"
                                @current-change="handleTaskTableCurrentChange"
                                class="float_right"
                                />
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="OBD故障" name="second">
                    <el-row>
                        <el-col :span="6" class="right-down-menu"><div class="grid-content ep-bg-purple-light" />
                            <el-date-picker v-model="selectedTimeRange" style="datePicker" type="datetimerange" range-separator="到" start-placeholder="请选择日期" end-placeholder="请选择日期" />
                        </el-col>
                        <el-col :span="8" class="right-down-menu">
                            <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                            <el-dropdown @command="handleModuleCommand" style="margin-left:20px" trigger="click">
                                <span class="el-dropdown-link-white">
                                    {{ selectedModuleName }} <el-icon class="el-icon--right"><ArrowDown /></el-icon>
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item v-for="(item, index) in moduleOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                            <el-dropdown @command="handleLevelCommand" style="margin-left:20px" trigger="click">
                                <span class="el-dropdown-link-white">
                                    {{ selectedLevelName }} <el-icon class="el-icon--right"><ArrowDown /></el-icon>
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item v-for="(item, index) in levelOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            <el-button type="primary" color="#00ACCD" @click="refreshMaterialOBDExceptions" class="btn">筛选</el-button>
                        </el-col>
                        <el-col :span="8"></el-col>
                        <el-col :span="2" style="text-align: end;"><el-button type="primary" color="#00ACCD" @click="exportMaterialOBDExceptions">导出</el-button></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-table ref="oBDTableRef"  :data="tableData" style="width: 100%; font-weight: 500;"  :height="pageWH.obdtableHeight"  @selection-change="handleSelectionChange">
                                <el-table-column type="selection" width="55" />
                                <el-table-column  prop="errorCode" label="错误码"></el-table-column>
                                <el-table-column prop="module" label="模块"></el-table-column>
                                <el-table-column prop="level" label="等级"></el-table-column>
                                <el-table-column prop="status" label="状态"></el-table-column>
                                <el-table-column prop="occurTimeStr" label="发生时间"  width="240"></el-table-column>
                                <el-table-column prop="receiveTime" label="接收时间" width="160"></el-table-column>
                                <!--el-table-column prop="clearTime" label="清除时间"></el-table-column-->
                                <el-table-column prop="occurVersion" label="故障发生版本号" width="130"></el-table-column>
                                <el-table-column prop="description" label="描述" width="480"></el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="9" class="grid-content"></el-col>
                        <el-col :span="15" class="pager-content">
                            <el-pagination
                                v-model:current-page="currentPage"
                                v-model:page-size="pageSize"
                                :page-sizes="[10, 20, 50, 100, 200]"
                                :small="small"
                                :disabled="disabled"
                                :background="background"
                                layout="total, prev, pager, next, sizes, jumper"
                                :total="totalNum"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                class="float_right"
                                />
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </el-col>
    </el-row>
  </template>
  
  
  <script>
  import { Search, Plus, Check, ArrowDown } from "@element-plus/icons-vue";
  import { reactive } from 'vue'
   // 如果 script用了setup, 则不能用export，可对比之前的文件
  export default {
    name: 'CompanyManager',
    data() {
            const activeName = 'first'
            const handleClick = (tab, event) => {
               // console.log(tab, event)
                if (tab.props.name == 'second') {
                    this.currentPage = 1
                    this.pageSize = 10
                    this.refreshMaterialOBDExceptions()
                }

                if (tab.props.name == 'first') {
                    this.taskTableCurrentPage = 1
                    this.taskTablePageSize = 10
                    this.refreshMaterialTask()
                }
            }
                
            const handleNodeClick = (data) => {
                console.log(data)
                this.planeForm.name = data.name;
                this.planeForm.description = data.description
                this.currClickNode = data
                this.selectedPlane = this.currClickNode.rights.split(',')
                this.planeDesc = data.description ? data.description : ''
                this.createdBy = data.createdByUser ? data.createdByUser : ''
                this.createdTime = data.createdTime ? data.createdTime : ''
            }
            const filterMethod = (query, node) => {
                 
            }
            const onQueryChanged = (value) => {
                console.log(value)
                 
            }
            const planeForm = reactive({
                id: '',
                name: '',
                description: '',
                rights: []
            })
            const tableForm = []   
            const tableData = []
            const taskTableData = []
            const materialDetail = {
                company: '',
                companeyId: '',
                createdTime: '',
                // Scrap_remaining: '300天',
                // bindingTime: '',
                // director: '陶',
                typeName: '',
             }

            const typeMap = {
                flightController: '飞控'
            }
            const statusMap  = {
                on: '在线',
                off: '离线'
            }

            const errorStatusMap  = {
                1: '未解决',
                0: '已解决'
            }

            const moduleOptionsMap = 
            {
                '0': '系统软件',
                "": '所有模块',
            }
            const taskStatusMap = {
                'flying': '飞行中',
                'landed': '已完成',
                'timeOut': '已超时',
            }
            const moduleOptions = [
                 {
                    value: '',
                    label: '所有模块',
                },
                {
                    value: '0',
                    label: '系统软件',
                }
            ]
            const levelOptions = [
                {
                    value: '',
                    label: '所有等级',
                },
                {
                    value: '1',
                    label: '等级1',
                },
                {
                    value: '2',
                    label: '等级2',
                },
                {
                    value: '3',
                    label: '等级3',
                },
                {
                    value: '4',
                    label: '等级4',
                }
            ]

            const planeImage = require('@/assets/device_medium.png')
            const showPlaneEdit = false
            const showPlaneView = true
            const treeData = []
            const selectedPlane = []
            const currClickNode = {}
            const companyMap = {}
            const searchTreeValue = ''
            const createdBy = ''
            const createdTime = ''
            const planeDesc = ''
            const editingPlaneId = ''
            const selectedTimeRange = null
            const selectedModule = ''
            const selectedModuleName = '所有模块'
            const selectedLevelName = '所有等级'
            const selectedLevel = ''
            const totalNum = 0
            const currentPage = 1
            const pageSize = 10
            const taskTableCurrentPage = 1
            const taskTablePageSize = 10
            const currMaterialId = ""
            const taskTableTotalNum = 0

            const handleSelectionChange = (selections) => {
                this.selectedRows = []
                this.selectedRows = selections.map(item => item.id);
            }

            const handleModuleCommand = (commandName) => {
                 this.selectedModule = commandName
                 this.selectedModuleName = commandName === '' ? "所有模块" : this.moduleOptionsMap[commandName]
                 this.refreshMaterialOBDExceptions()
            }

            const handleLevelCommand= (commandName) => {
                this.selectedLevel = commandName
                this.selectedLevelName = commandName === '' ? "所有等级" : ("等级" + commandName)
                this.refreshMaterialOBDExceptions()
            }

            const handleLink = (index, row) => {
                this.$router.push({ path: '/flightreport',
                    // name: 'materialrecordview',
                    params: {
                        lineId: row.lineId,
                        taskId: row.taskId,
                        taskCode: row.taskCode
                    },
                    query: {
                        lineId: row.lineId,
                        taskId: row.id,
                        taskCode: row.taskCode,
                        materialCode: row.sn,
                        tasktableIndex: this.taskTableCurrentPage,
                        tasktableSize: this.taskTablePageSize
                    }
                })
            }

            const handleSizeChange = (val) => {
                console.log(`${val} items per page`)
                this.pageSize = val
                this.currentPage = 1
                this.refreshMaterialOBDExceptions();
            }
            const handleCurrentChange = (val) => {
                console.log(`current page: ${val}`)
                this.currentPage = val
                this.refreshMaterialOBDExceptions();
            }

            const handleTaskTableSizeChange = (val) => {
                console.log(`${val} items per page`)
                this.taskTablePageSize = val
                this.taskTableCurrentPage = 1
                this.refreshMaterialTask();
            }
            const handleTaskTableCurrentChange = (val) => {
                console.log(`current page: ${val}`)
                this.taskTableCurrentPage = val
                this.refreshMaterialTask();
            }

            const handleAdd = () => {
                this.showPlaneEdit = true
                this.showPlaneView = false
                this.editingPlaneId = ''
            }

            const saveEdit = () => {
                this.showPlaneEdit = false
                this.showPlaneView = true
                this.addPlane()
            }

            const cancelEdit = () => {
                this.showPlaneEdit = false
                this.showPlaneView = true
                this.editingPlaneId =  ''
            }

            const gotoEdit = () => {
                this.showPlaneEdit = true
                this.showPlaneView = false
                this.editingPlaneId =  this.currClickNode.id
            }

            const deletePlane = () => {
                console.log('delete plane')
                this.deletePlaneById(this.currClickNode.id)
                this.showPlaneEdit = false
                this.showPlaneView = true
            }

            return {
                Search,
                Plus,
                Check,
                ArrowDown,
                selectedRows: [],
                currClickNode,
                createdBy,
                createdTime,
                taskStatusMap,
                companyMap,
                planeDesc,
                showPlaneView,
                showPlaneEdit,
                planeForm,
                moduleOptionsMap,
                moduleOptions,
                levelOptions,
                selectedTimeRange,
                selectedLevel,
                selectedLevelName,
                selectedModuleName,
                selectedModule,
                materialDetail,
                tableForm,
                selectedPlane,
                tableData,
                taskTableData,
                totalNum,
                pageSize,
                currentPage,
                taskTableCurrentPage,
                taskTablePageSize,
                taskTableTotalNum,
                currMaterialId,
                searchTreeValue,
                treeData,
                editingPlaneId,
                activeName,
                planeImage,
                typeMap,
                statusMap,
                errorStatusMap,
                pageWH: {},
                handleSelectionChange,
                handleSizeChange,
                handleCurrentChange,
                handleTaskTableSizeChange,
                handleTaskTableCurrentChange,
                handleClick,
                handleNodeClick,
                handleLink,
                filterMethod,
                onQueryChanged,
                handleModuleCommand,
                handleLevelCommand,
                handleAdd,
                saveEdit,
                cancelEdit,
                gotoEdit,
                deletePlane
            };
        },
        methods: {
            exportMaterialOBDExceptions () {
                // 导出当前页的已有数据
                console.log('export material OBD Exceptions')
                this.$store.dispatch('materialrecord/exportMaterialOBDExceptionlist', {
                    // 转接一下
                    pageIndex: this.currentPage,
                    pageSize: this.pageSize,
                    searchName: this.searchName,
                    level: this.selectedLevel,
                    module: this.selectedModule == '' ? null : this.selectedModule,
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : "",
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : "",
                    materialCode: this.materialDetail.materialCode,
                    obdErrorCodeIds: this.selectedRows
                }).then((response) => {
                        const blob = new Blob([response.data]);
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = "故障码-" + this.materialDetail.materialCode + ".xlsx";
                        link.click();
                        window.URL.revokeObjectURL(downloadUrl);
                }).catch(() => {
                    this.loading = false
                })
            },
            refreshMaterialOBDExceptions () {
                console.log('query material OBD Exceptions')
                this.$store.dispatch('materialrecord/queryMaterialOBDExceptionlist', {
                    pageIndex: this.currentPage,
                    pageSize: this.pageSize,
                    searchName: this.searchName,
                    level: this.selectedLevel,
                    module: this.selectedModule == '' ? null : this.selectedModule,
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : "",
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : "",
                    materialCode: this.materialDetail.materialCode
                }).then((data) => {
                    this.loading = false
                    this.tableData = data.list
                    for (let inx in this.tableData) {
                        this.tableData[inx].module = this.moduleOptionsMap[this.tableData[inx].module]
                        this.tableData[inx].status = this.errorStatusMap[this.tableData[inx].status]
                        this.tableData[inx].occurTimeStr = this.tableData[inx].occurTime + " (第" + this.tableData[inx].occurPoweredTimes + "次上电)"
                    }
                    this.totalNum = data.total
                    this.currentPage = data.pageIndex
                }).catch(() => {
                    this.loading = false
                })
            },
            refreshMaterialTask () {
                console.log('query material task')
                this.$store.dispatch('materialrecord/querymaterialtasks', {
                    pageIndex: this.taskTableCurrentPage,
                    pageSize: this.taskTablePageSize,
                    materialCode: this.materialDetail.materialCode
                }).then((data) => {
                    this.loading = false
                    this.taskTableData = data.list
                    this.taskTableCurrentPage = data.pageIndex
                    this.taskTableTotalNum = data.total
                    for(let inx in this.taskTableData) {
                        this.taskTableData[inx].status = this.taskStatusMap[this.taskTableData[inx].status]
                        this.taskTableData[inx].totalTimeCost = this.buildFormatTime(this.taskTableData[inx].totalTime)
                        this.taskTableData[inx].totalDistanceKM = this.taskTableData[inx].totalDistance/1000
                    }
                }).catch(() => {
                    this.loading = false
                })
            },
            addPlane() {
                if(this.editingPlaneId != null && this.editingPlaneId != '') {
                    this.updatePlaneById()
                } else {
                    console.log('add plane')
                    this.$store.dispatch('plane/createplane', {
                        name: this.planeForm.name,
                        description: this.planeForm.description,
                        rights: this.selectedPlane.join(',')
                    }).then(() => {
                        this.loading = false
                        this.initPlanes();
                    }).catch(() => {
                        this.loading = false
                    })
                }
            },
            buildFormatTime(seconds) {
                let hours = Math.floor(seconds / 3600);
                let minutes = Math.floor((seconds % 3600) / 60);
                let remainingSeconds = seconds % 60;
                let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
                return formattedTime
            },
            refreshPlaneInfo() {
                console.log('query plane')
                this.$store.dispatch('plane/queryplanebyid', {
                    planeId: this.planeId
                }).then((data) => {
                    this.loading = false
                    this.treeData = data
                }).catch(() => {
                    this.loading = false
                })
            },
            refreshmaterialInfo() {
                console.log('query material')
                this.$store.dispatch('material/queryMaterialInfoByCode', {
                    materialCode: this.materialDetail.materialCode
                }).then((data) => {
                    this.loading = false
                    this.materialDetail.createdTime = data.createdTime
                    this.materialDetail.typeName = this.typeMap[data.type]
                    this.materialDetail.companeyId = data.companyId
                    this.materialDetail.company = this.companyMap[data.companyId]
                }).catch(() => {
                    this.loading = false
                })
            },
            initCompanyOptions() {
                console.log('query company')
                this.dialogFormVisible = false
                this.$store.dispatch('company/querycompanylist', {
                    pageIndex: 1,
                    pageSize: 2000,
                    searchName: ''
                }).then((data) => {
                    this.companyMap = {}
                    for (let key in data.list) {
                        this.companyMap[data.list[key].id] = data.list[key].name
                    }
                    // 再刷新数据
                    this.refreshmaterialInfo()
                }).catch(() => {
                    this.loading = false
                })
            },
            initPage() {
                var browerWidth = window.innerWidth; //浏览器可视宽度
                var browerHeight = window.innerHeight; //浏览器可视宽度
                if (browerHeight < 739) {
                    browerHeight = 739
                } 
                this.pageWH.obdtableHeight =  (browerHeight - 739 ) + 245
                this.pageWH.tasktableHeight =  (browerHeight - 739 ) + 303
            }
        },
        mounted: function() {
            this.planeForm = {
                id: '',
                name: '',
                description: '',
                rights: []
             }

             this.materialDetail = {
                company: this.$route.query.companyName,
                companeyId: this.$route.query.companyId, 
                // director: '陶渊明',
                typeName: '',
                materialCode: this.$route.query.materialCode
             }

             this.taskTableCurrentPage = this.$route.query.tasktableIndex ? parseInt(this.$route.query.tasktableIndex) : this.taskTableCurrentPage
             this.taskTablePageSize = this.$route.query.tasktableSize ?  parseInt(this.$route.query.tasktableSize) : this.taskTablePageSize

            this.treeData = this.data
            this.initPage()
            this.refreshMaterialOBDExceptions()
            this.refreshMaterialTask()
            this.initCompanyOptions()
            window.addEventListener('resize', this.initPage)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.initPage)
        },
        components: {
            Check,
            ArrowDown
        }
    }; 
  </script>
  <style  lang="scss">
  /* 手动配置全局样式 */
  .el-row {
    display: flex;     /*  align-items: center; */
    padding: 10px;
    margin: 5px;
    background: white;
  }
  /* 背景颜色 */
  .aside{
    background-color: white;
  }

  .main-content {
    display: flex;
    align-items: flex-start;
  }
  
  .left-top {
    background: white;
    height: 220px;
  }

  .left-bottom {
    background: white;
    height: 495px;
    overflow: auto;
    padding: 15px;
  }

  .right-top {
    height: 180px;
    background: white;
  }

  .right-bottom  {
    height: 377px;  /* 影响权限表格的高度和  */
    background: white;
    margin-left: 15px;
    margin-right: 15px;
  }
 
  .plane-form-label {
    justify-content: center !important;
  }

  .plane-form {
    margin-top: 20px !important;
    margin-right: 20px !important;
  }

  .el-tree {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    padding: 10px;
    color: #000;
    --el-tree-node-hover-bg-color: #00ACCD;
  }

  .customWidth {
    width: 450px !important;
  }

  .float_right {
    float: right;
  }


// 树控件-节点之间
.el-tree-node {
    margin: 8px;
}

// 树控件-原有的箭头 去掉
.el-tree .el-icon svg{   
  display: none !important;
  height: 0;
  width: 0;
}

// 树控件-引入的图标（图片）size大小 => 树节点前的预留空间大小
.el-tree-node__expand-icon{ 
  font-size:16px;
}
 
// 树控件-图标是否旋转，如果是箭头类型的，可以设置旋转90度。如果是两张图片，则设为0
.el-tree .el-tree-node__expand-icon.expanded  {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

// 树控件-叶子节点（不显示图标）
.el-tree .is-leaf.el-tree-node__expand-icon::before { 
  display: block;
  background: none !important;
  content: '';
  width: 18px;
  height: 18px;
}

.el-button.is-round {
    padding: 8px 15px;
    border-radius: 4px;
    background: #00accd;
}

.statistic {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.option {
    margin-left: 10px;
    margin-top: -5px !important;
  }

  .datepicker {
    margin-right: 10px !important;
  }

  .btn {
    margin-top: -5px !important;
    margin-left: 20px;
  }

  .el-table .el-table__header th {
    background-color: #F4F7FA !important;
    border-right: 0 none；
  }

  .el-date-editor{
    width: -webkit-fill-available;
  }

  .el-dropdown-link:focus {
    outline: none;   /* 去除，悬浮在el-dropdown时，黑色边框, */
  }

  .el-descriptions__body .el-descriptions__table:not(.is-bordered) .el-descriptions__cell {
    padding-bottom: 2px !important;
  }
</style>

<style scoped>
  .descItem {
    font-weight: 500 !important;
    font-family: PingFang SC, PingFang SC-Medium;
    text-align: left;
    color: #333333;
  }

  .detail-label {
    width: 100px;
    color: #7E8A95 !important;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
  }

  .el-descriptions-item {
    display: block;
  }

  .el-descriptions__body .el-descriptions__content {
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 600;
    text-align: left;
    color: #333333;
  }
</style>